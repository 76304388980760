import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'

const Contact = (props) => (
    <header id="contact" style={props.timeout ? {display: 'none'} : {}}>
        <div className="logo">
            <Link to="/"><span className="icon fa-book"></span></Link>
        </div>
        <div className="content">
            <div className="inner">
                <h1>New Haven Speculative Fiction Workshop</h1>
                <h3>Contact</h3>
                <form method="post" netlify-honeypot="bot-field" data-netlify="true">
                    <input type="hidden" name="bot-field" />
                        <div className="field half first">
                            <label htmlFor="name">Name</label>
                            <input type="text" name="name" id="name" />
                        </div>
                    <div className="field half">
                        <label htmlFor="email">Email</label>
                        <input type="text" name="email" id="email" />
                    </div>
                    <div className="field">
                        <label htmlFor="message">Message</label>
                        <textarea name="message" id="message" rows="4"></textarea>
                    </div>
                    <ul className="actions">
                        <li><input type="submit" value="Send Message" className="special" /></li>
                        <li><input type="reset" value="Reset" /></li>
                    </ul>
                </form>
                <ul className="icons">
                    <li><a href="https://www.twitter.com/NHSFW" className="icon fa-twitter"><span className="label">Twitter</span></a></li>
                    <li><a href="https://www.twitter.com/NHSFW" className="icon fa-facebook"><span className="label">Facebook</span></a></li>
                    <li><a href="https://www.twitter.com/NHSFW" className="icon fa-instagram"><span className="label">Instagram</span></a></li>
                    <li><a href="https://www.twitter.com/NHSFW" className="icon fa-github"><span className="label">GitHub</span></a></li>
                </ul>
                <p><Link to="/">Go back to the homepage</Link></p>
            </div>
        </div>
        <nav>
            <ul>
                <li><Link to="/introduction">About</Link></li>
                <li><Link to="/members">Members</Link></li>
                <li><Link to="/rules">Rules</Link></li>
                <li><Link to="/contact">Contact</Link></li>
            </ul>
        </nav>
    </header>
)

Contact.propTypes = {
    onOpenArticle: PropTypes.func,
    timeout: PropTypes.bool
}

export default Contact




 
